import { animate, stagger, inView, timeline, spring, scroll } from "motion"

  // PROGRESS CIRCLE
  scroll(animate(".progress", { strokeDasharray: ["0,1", "1,1"] }));


  // INTRO SLIDE

  var el = document.getElementById("introTextFade");
  if(el) {
    inView(el, (info) => {
      animate(info.target,
        { opacity: "1" },
        { delay: 0.5, duration: 1.0 }
      )
    })
  }

  var el = document.getElementById("introFam");
  if(el) {
    inView(el, (info) => {
      animate(info.target,
        { opacity: "1" },
        { delay: 1.0, duration: 1.0 }
      )
    })
  }


  const draw = (progress) => ({
    // This property makes the line "draw" in when animated
    strokeDashoffset: 1 - progress,

    // Each line will be hidden until it starts drawing
    // to fix a bug in Safari where the line can be
    // partially visible even when progress is at 0
    visibility: "visible",
  })

  timeline([
    ["circle#circle1", draw(1), { duration: 0.8, delay: 1.5 }],
    ["circle#circle2", draw(1), { duration: 0.8, delay: 0 }]
  ])

  var el = document.getElementById("number1");
  inView(el, (info) => {
    animate(info.target,
      { opacity: "1" },
      { delay: 1.75, duration: 1.0 }
    )
  })

  var el = document.getElementById("number1Text");
  inView(el, (info) => {
    animate(info.target,
      { opacity: "1" },
      { delay: 1.75, duration: 1.0 }
    )
  })

  var el = document.getElementById("number2");
  if(el) {
    inView(el, (info) => {
      animate(info.target,
        { opacity: "1" },
        { delay: 3, duration: 1.0 }
      )
    })
  }

  var el = document.getElementById("number2Text");
  if(el) {
    inView(el, (info) => {
      animate(info.target,
        { opacity: "1" },
        { delay: 3.25, duration: 1.0 }
      )
    })
  }



// CARDS
const card1 = document.querySelectorAll("#card1")
const card2 = document.querySelectorAll("#card2")
const card3 = document.querySelectorAll("#card3")
const card4 = document.querySelectorAll("#card4")
const cardSideText = document.querySelectorAll("#cardSideText")
const cardSideTextMobile = document.querySelectorAll("#cardSideTextMobile")
inView(card1, (info) => {
  animate(
    card1,
    { scale: [.7, 1.1, 1.0], opacity: 1 },
    { delay: .6, easing: spring() }
  )
  animate(
    card2,
    { scale: [.7, 1.1, 1.0], opacity: 1 },
    { delay: .9, easing: spring() }
  )
  animate(
    card3,
    { scale: [.7, 1.1, 1.0], opacity: 1 },
    { delay: 1.2, easing: spring() }
  )
  animate(
    card4,
    { scale: [.7, 1.1, 1.0], opacity: 1 },
    { delay: 1.5, easing: spring() }
  )
  animate(
    cardSideText,
    { top: "0", opacity: 1 },
    { delay: 1.8, duration: 1.0 }
  )
  animate(
    cardSideTextMobile,
    { top: "0", opacity: 1 },
    { delay: 0, duration: 1.0 }
  )
})


// ROLES

const roleTitle = document.querySelectorAll("#roleTitle")
const roleArt = document.querySelectorAll("#roleArt")
const roleChat1 = document.querySelectorAll("#roleChat1")
const roleChat2 = document.querySelectorAll("#roleChat2")
const rolePlus = document.querySelectorAll("#rolePlus")
inView(roleArt, (info) => {
  animate(roleTitle,
    { top: [ "-50px", "10px", "0" ], opacity: "1" },
    { delay: 0, duration: .25, easing: spring() }
  )
  animate(roleChat1,
    { opacity: "1" },
    { delay: .5, duration: 1.0 }
  )
  animate(roleChat2,
    { opacity: "1" },
    { delay: 1, duration: 1.0 }
  )
  animate(rolePlus,
    { opacity: "1" },
    { delay: .75, duration: 1.0 }
  )
})




inView(".horzFade1", (info) => {
  animate(info.target, { opacity: "1" },
  { delay: 0.75, duration: 1.0 }
  )
})


inView(".horzFade3", (info) => {
  animate(info.target, { opacity: "1" },
  { delay: 2.5, duration: 1.0 }
  )
})







  // TEXT BG FADES
  const bgFade = document.querySelectorAll(".bgFade")
  inView(bgFade, (info) => {
    animate(info.target, { opacity: "1" },
    { delay: 1.25, duration: 1.0 }
    )
  })

  const bgFadeOpacity = document.querySelectorAll(".bgFadeOpacity")
  inView(bgFadeOpacity, (info) => {
    animate(info.target, { backgroundColor: "rgba(224, 218, 245, 1)" },
    { delay: 1.5, duration: 1.0 }
    )
  })


  // ELEMENT 1: Large middle intro bubble
  var el = document.getElementById("fadeIn");

  if(el) {
    inView("#fadeIn", (info) => {
      animate(info.target,
      { margin: "-40px auto 0px auto", opacity: 1 },
      { delay: 0.5, duration: 1.0 }
      )
    })
  }

  // ELEMENT 1: Fade in the small bubbles

  const bubble1 = document.querySelectorAll(".fadeIn1")
  const bubble2 = document.querySelectorAll(".fadeIn2")
  const bubble3 = document.querySelectorAll(".fadeIn3")
  const bubble4 = document.querySelectorAll(".fadeIn4")
  const bubble5 = document.querySelectorAll(".fadeIn5")
  const bubble6 = document.querySelectorAll(".fadeIn6")
  const bubble7 = document.querySelectorAll(".fadeIn7")
  const bubble8 = document.querySelectorAll(".fadeIn8")
  inView(bubble1, (info) => {
    animate(info.target,
      { opacity: "1" },
      { delay: 1.5, duration: 1.0 }
    )

    animate(bubble2,
      { opacity: "1" },
      { delay: 1.6, duration: 1.0 }
    )

    animate(bubble3,
      { opacity: "1" },
      { delay: 1.7, duration: 1.0 }
    )

    animate(bubble4,
      { opacity: "1" },
      { delay: 1.8, duration: 1.0 }
    )

    animate(bubble5,
      { opacity: "1" },
      { delay: 1.9, duration: 1.0 }
    )

    animate(bubble6,
      { opacity: "1" },
      { delay: 2.0, duration: 1.0 }
    )

    animate(bubble7,
      { opacity: "1" },
      { delay: 2.1, duration: 1.0 }
    )

    animate(bubble8,
      { opacity: "1" },
      { delay: 2.2, duration: 1.0 }
    )
  })

  // Grow and shrink the small bubbles
  const bubbles1 = document.querySelectorAll(".fadeIn1")
  animate(bubbles1,
    { transform: "scale(1.25)" },
    { delay: 2.0, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles2 = document.querySelectorAll(".fadeIn2")
  animate(bubbles2,
    { transform: "scale(1.25)" },
    { delay: 2.25, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles3 = document.querySelectorAll(".fadeIn3")
  animate(bubbles3,
    { transform: "scale(1.25)" },
    { delay: 2.5, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles4 = document.querySelectorAll(".fadeIn4")
  animate(bubbles4,
    { transform: "scale(1.25)" },
    { delay: 2.75, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles5 = document.querySelectorAll(".fadeIn5")
  animate(bubbles5,
    { transform: "scale(1.25)" },
    { delay: 3.0, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles6 = document.querySelectorAll(".fadeIn6")
  animate(bubbles6,
    { transform: "scale(1.25)" },
    { delay: 3.25, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles7 = document.querySelectorAll(".fadeIn7")
  animate(bubbles7,
    { transform: "scale(1.25)" },
    { delay: 3.5, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  const bubbles8 = document.querySelectorAll(".fadeIn8")
  animate(bubbles8,
    { transform: "scale(1.25)" },
    { delay: 3.75, duration: 1.0, repeat: Infinity, direction: "alternate" }
  )



  const titleDown = document.querySelectorAll(".titleDown")
  inView(titleDown, (info) => {
    animate(info.target,
      { top: "-50px", opacity: "1" },
      { delay: 1.0, duration: 1.0 }
    )
  })


// Hearts
const heartsFadeIn = document.querySelectorAll(".heartsFadeIn")
inView(heartsFadeIn, (info) => {
  animate(info.target,
    { opacity: "1" },
    { delay: 0.5, duration: 1.0 }
  )
})


  // ELEMENT 2: Baby Circle Fade In
  const circleFadeIn = document.querySelectorAll(".circleFadeIn")
  const babyFadeIn = document.querySelectorAll(".babyFadeIn")
  const babyFadeInOnly = document.querySelectorAll(".babyFadeInOnly")
  inView(circleFadeIn, (info) => {
    animate(info.target,
      { opacity: "1" },
      { delay: 0.5, duration: 1.0 }
    )
    animate(babyFadeIn,
      { scale: [.7, 1.2, 1.0], opacity: 1 },
      { delay: 1.2, easing: spring() }
    )
    animate(babyFadeInOnly,
      { opacity: 1 },
      { delay: 0.5, duration: 1.0 }
    )
  })

  const babyTextFromRight = document.querySelectorAll(".babyTextFromRight")
  inView(babyTextFromRight, (info) => {
    animate(info.target, { right: "0", opacity: "1" },
    { delay: 1.5, duration: 1.0 }
    )
  })

  // Bring Text In From Right
  const textFromRight = document.querySelectorAll(".textFromRight")
  inView(textFromRight, (info) => {
    animate(info.target, { right: "0" },
    { delay: 0.5, duration: 1.0 }
    )
  })

  // Bring Text In From Left
  const textFromLeft = document.querySelectorAll(".textFromLeft")
  inView(textFromLeft, (info) => {
    animate(info.target, { left: "0", opacity: "1" },
    { delay: 0.5, duration: 1.0 }
    )
  })

  // Bring Text In From Bottom
  const textFromBottom = document.querySelectorAll(".textFromBottom")
  inView(textFromBottom, (info) => {
    animate(info.target, { top: "0" },
    { delay: 0.5, duration: 1.0 }
    )
  })

  const headingReveal = document.querySelectorAll(".headingReveal")
  inView(headingReveal, (info) => {
    animate(info.target, { opacity: "1", transform: "translate3d(0%,0%,0) scale3d(1,1,1)" },
    { delay: 1.0, duration: 1.0 }
    )
  })

  // ELEMENT 3: Sense bubbles
  const senses1 = document.querySelectorAll(".senses1")
  const senses2 = document.querySelectorAll(".senses2")
  const senses3 = document.querySelectorAll(".senses3")
  inView(senses3, (info) => {
    animate(senses1,
      { scale: [.7, 1.2, 1.0], opacity: 1 },
      { delay: .5, easing: spring() }
    )
  animate(senses2,
      { scale: [.7, 1.2, 1.0], opacity: 1 },
      { delay: .7, easing: spring() }
    )
    animate(senses3,
      { scale: [.7, 1.2, 1.0], opacity: 1 },
      { delay: .9, easing: spring() }
    )
  })


  const pulse = document.querySelectorAll(".pulse")
  animate(pulse,
    { transform: "scale(1.2)" },
    { delay: stagger(1.0, { start: 2.0 }), duration: 1.0, repeat: Infinity, direction: "alternate" }
  )

  // ELEMENT 5: Chat Bubbles

  const chatBubble1 = document.querySelectorAll(".chatBubble1")
  const chatBubble2 = document.querySelectorAll(".chatBubble2")
  const chatBubbleText = document.querySelectorAll(".chatBubbleText")
  inView(chatBubble1, (info) => {
    animate(chatBubble1,
      {top: "0px", opacity: "1" },
      { delay: 0.5 }
    )
    animate(chatBubble2,
      {top: "48px", opacity: "1" },
      { delay: 1.0 }
    )
    animate(chatBubbleText,
      { top: "0" },
      { delay: 1.1, duration: 1.0 }
    )
  })

  const simpleFadeIn = document.querySelectorAll(".simpleFadeIn")
  inView(simpleFadeIn, (info) => {
    animate(info.target, { opacity: "1" },
    { delay: 0.5, duration: 1.0 }
    )
  })

  // Chat bubbles
  const bubblesDown = document.querySelectorAll(".bubblesDown")
  inView(bubblesDown, (info) => {
    animate(info.target, { top: "60px", opacity: "1" },
    { delay: 1.0, duration: 2.0 }
    )
  })

  const bubblesDownMobile = document.querySelectorAll(".bubblesDownMobile")
  inView(bubblesDownMobile, (info) => {
    animate(info.target, { top: "10px", opacity: "1" },
    { delay: 2.0, duration: 2.0 }
    )
  })



  // Child 1
  const child1FromRight = document.querySelectorAll(".child1FromRight")
  inView(child1FromRight, (info) => {
    animate(info.target, { right: "0" },
    { delay: 1.25, duration: 1.0 }
    )
  })

  // Child 2
  const child2FromRight = document.querySelectorAll(".child2FromRight")
  inView(child2FromRight, (info) => {
    animate(info.target, { right: "-110px" },
    { delay: 1.5, duration: 1.0 }
    )
  })

  // Child 3
  const child3FromRight = document.querySelectorAll(".child3FromRight")
  inView(child3FromRight, (info) => {
    animate(info.target, { right: "-235px" },
    { delay: 3.0, duration: 1.0 }
    )
  })

  // Caregiver
  const caregiverFromRight = document.querySelectorAll(".caregiverFromRight")
  inView(caregiverFromRight, (info) => {
    animate(info.target, { right: "-277px" },
    { delay: 3.25, duration: 1.0 }
    )
  })

  const bgButton = document.querySelectorAll(".bgButton")
  animate(bgButton,
    { backgroundColor: "#ffc53" },
    { duration: 3.0, repeat: Infinity }
  )


  // GUIDES

  const guidePeople = document.querySelectorAll(".guidePeople")
  inView(guidePeople, (info) => {
    animate(info.target,
      { opacity: "1" },
      { delay: 0.5, duration: 1.0 }
    )
  })

  const guideText = document.querySelectorAll(".guideText")
  inView(guideText, (info) => {
    animate(info.target,
      { right: "0", opacity: "1" },
      { delay: 1.0, duration: 1.0 }
    )
  })